//from https://stackoverflow.com/a/2144404/830213
export function getCookie(name) {
  let matchingCookie = document.cookie.split(';').filter(c => {
    let [key, value] = c.split('=');
    if(key == name) {
      return value
    }
  })[0];

  return matchingCookie?.split('=')[1]
}

export function setCookie(name, value, days = 7) {
  let date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  let expires = "; expires=" + date.toUTCString();
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
}

export function deleteCookie(name) {
  if (getCookie(name)) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}

export async function getWebReleases(currentVersion) {
  console.log('fetch')

  try {
    let versionsResponse = await fetch('/.internal/deploys')
    console.log(versionsResponse)
    let versions = await versionsResponse.json()
    console.log(versions)

    versions.forEach(version => {
      version.selected = version.name.includes(currentVersion)
    });

    versions.sort((a,b) => b.name > a.name ? 1 : -1)

    return versions

  } catch (error) {
    console.error(error)
    return []
  }
}