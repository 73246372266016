<template>

  <div class="version-wrapper">
    <button @click="resetVersion">Reset Version</button>
    <ul class="version-list">
      <li v-for="version in releases" :key="version.name" 
        :class="{ 'selected': version.selected }"
        @click="setVersion(version.name)"
      >
        {{ version.name }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { getCookie, setCookie, deleteCookie, getWebReleases } from './helpers.js'

let resetVersion = () => {
  deleteCookie('__versions')
  location.reload()
}

let setVersion = (version) => {
  const versionTag = version.split(':')[1]
  setCookie('__versions', versionTag)
  location.reload()
}

let currentVersion = getCookie('__versions') || window.siteInfo?.version
console.log('currentVersion', currentVersion)

let releases = []

releases = await getWebReleases(currentVersion)

console.log(releases)

</script>

<style>
li {
  list-style: none;
  cursor: pointer;
  padding: 5px;
  border: 1px solid grey;
}

li.selected {
  border: 3px solid grey;
  background: lime;
}
</style>